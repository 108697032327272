import React from "react";
import '../assets/css/templates/app-template.css';
import { Helmet } from "react-helmet";
import Footer from "../components/Footer";
import Header, { CurrentPageType } from "../components/Header";
import { ContextWithAppsItemProps } from "../types/appsItem";
import { Link } from "gatsby";
import ReactGA from 'react-ga';

const AppTemplate: React.FC<ContextWithAppsItemProps> = ( { pageContext: { id, name, iconName, iosLink, androidLink} }) => {
  const data = require(`../data/appsDataContent/${id}.html`).default;
   
    React.useEffect(() => {
  		ReactGA.initialize('UA-88504612-1');
  		ReactGA.pageview(`/apps/${id}`);
  	}, []);
  	
  return (
    <React.Fragment>
      <Helmet>
        <title>{`Pixel Works Software: ${name} - Mobile App`}</title>
        <meta name="description" content={`Learn more about ${name}, a mobile app by Pixel Works Software. Download for iOS and Android devices.`} />
        <meta name="keywords" content={`${name}, mobile app, pixel works software, game solver, iOS app, Android app`} />
        
        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content={`https://www.pixelworkssoftware.com/apps/${id}`} />
        <meta property="og:title" content={`Pixel Works Software: ${name} - Mobile App`} />
        <meta property="og:description" content={`Learn more about ${name}, a mobile app by Pixel Works Software. Download for iOS and Android devices.`} />
        <meta property="og:image" content={`https://www.pixelworkssoftware.com/static/${iconName}`} />
        
        {/* Twitter */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:url" content={`https://www.pixelworkssoftware.com/apps/${id}`} />
        <meta name="twitter:title" content={`Pixel Works Software: ${name} - Mobile App`} />
        <meta name="twitter:description" content={`Learn more about ${name}, a mobile app by Pixel Works Software. Download for iOS and Android devices.`} />
        <meta name="twitter:image" content={`https://www.pixelworkssoftware.com/static/${iconName}`} />
        
        {/* Canonical URL */}
        <link rel="canonical" href={`https://www.pixelworkssoftware.com/apps/${id}`} />
      </Helmet>
      <div className="gradient"></div>
      <Header currentPage={CurrentPageType.APPS} />
      <div className="gradient"></div>
      <main className="apps-template__container">
        <Link className="apps-template__back-button" to="/apps">⇦ BACK</Link>
        <h1 className="apps-template__name">{name}</h1>
        <div className="apps-template__description-container">
          <img className="apps-template__icon" src={require(`../assets/images/${iconName}`).default} alt={`${name} app icon`} width={200} height={200}/>
          <div className="apps-template__description-content" dangerouslySetInnerHTML={{__html: data}}></div> 
        </div>
        <div className="apps-template__app-store-links">
          {iosLink && (
            <a className="apps-template__ios-link" href={iosLink} target="_blank" aria-label={`Download ${name} on the App Store`}>Download on AppStore</a>
          )}
          {androidLink && (
            <a className="apps-template__android-link" href={androidLink} target="_blank" aria-label={`Download ${name} on Google Play`}>Download on Google Play</a>
          )}
        </div>
      </main>
      <Footer />
    </React.Fragment>
  );
}

export default AppTemplate;
